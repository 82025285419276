import React from 'react'
import { SessionProvider } from './contexts/session'
import { Content } from './components/content'

import './App.css'

const App = () => {
  return (
    <SessionProvider>
        <Content/>
    </SessionProvider>
  )
}

export default App
