export const Loader: React.FC = () => {
  const rotationKeyframes = `
    @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    }
  `

  return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', }}>
    <span style={
      {
        width: '48px',
        height: '48px',
        border: '5px solid #FFF',
        borderBottomColor: '#2563EB',
        borderRadius: '50%',
        display: 'inline-block',
        boxSizing: 'border-box',
        animation: 'rotation 1s linear infinite',
      }
    }>
      <style>{rotationKeyframes}</style>
  </span>
  </div>
 }
