type LoginProps = {
  loginWithPopup: () => void
}

export const Login: React.FC<LoginProps> = ({ loginWithPopup }) => {
  return (
    <div className='h-full bg-gray-700 flex flex-col items-center justify-center'>
      <div className='h-full md:h-fit w-full md:w-[416px] flex flex-col'>
        <div className='flex flex-col gap-4 p-6 bg-gray-100 justify-center items-center antialiased font-extrabold'>
          <h1 className='text-5xl'>Centre d’aide</h1>
          <p className='text-base antialiased font-normal text-2xl'>Connexion</p>
        </div>
        <div
          className='grow md:grow-0 flex flex-col gap-5 p-6 justify-start items-center bg-white md:rounded-lg'
        >
          <p className='text-center text-base antialiased font-normal text-gray-500'> Connectez-vous avec votre compte pour accéder au Guide </p>
          <button className='h-12 p-2 border-0 rounded-lg text-base antialiased font-medium text-white bg-blue-600 hover:bg-blue-800'
            onClick={() => loginWithPopup()}
          >
            <p style={{ padding: '4px' }}>
              Se connecter
            </p>
          </button>
        </div>
      </div>
    </div>
  )

}
