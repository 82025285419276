import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { Login } from '../components/login'
import { Loader } from '../components/loader'

interface ISessionContext {
  token: string | null
}

export const SessionContext = createContext<ISessionContext>({ token: null })
export const useSessionContext: () => ISessionContext = () => useContext(SessionContext)

export const SessionProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null)
  const { isAuthenticated, loginWithPopup, getAccessTokenSilently, logout, isLoading } = useAuth0()

  useEffect(() => {
    if (window.location.pathname === '/logout') {
      logout({ logoutParams: { returnTo: 'https://finspot.co' } })

      return
    }

    if (isAuthenticated && !token) {
      window.location.reload()
      return
    }

    const checkAndLogin = async () => {
      if (!isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently()
          setToken(accessToken)
          return
        } catch (e) {
        }
      }
    }

    checkAndLogin()
  }, [isAuthenticated, loginWithPopup, getAccessTokenSilently, logout, token])

  return (
    <SessionContext.Provider value={{ token }}>
      {
        isLoading
          ? <Loader/>
          : token
            ? children
            : <Login loginWithPopup={loginWithPopup} />
      }
    </SessionContext.Provider>
  )
}
